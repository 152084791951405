import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import PageHeader from '../../components/page-header'
import PageIntro from '../../components/page-intro'
import Block from '../../components/waypoint-block'
import Drift from '../../components/waypoint-drift'

function CareersPage({ data }) {
  return (
    <React.Fragment>
      <Helmet
        title="Versett – Careers"
        meta={[
          {
            name: 'description',
            content:
              'Versett is designed to be a place for doing meaningful work. Our team is tight-knit and dedicated to building great products.',
          },
          { name: 'keywords', content: 'versett, career, job, work' },
          { property: 'og:title', content: 'Versett – Careers' },
          {
            property: 'og:description',
            content:
              'Versett is designed to be a place for doing meaningful work. Our team is tight-knit and dedicated to building great products.',
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-careers.jpg',
          },
          { property: 'og:url', content: 'https://versett.com/careers/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Careers' },
        ]}
      >
        <html lang="en" className="s-careers" />
        <script
          src="https://you.bamboohr.com/js/embed.js"
          type="text/javascript"
          async
          defer
        ></script>
      </Helmet>
      <Layout>
        <section className="s-container s-page__hero s-page__hero--short">
          <PageHeader lede="Careers" subheading="A culture of innovation" />
        </section>
        <Drift />
        <Block>
          <section className="s-container">
            <picture className="c-image c-image--fullscreen">
              <img
                src="/images/careers.jpg"
                alt="People collaborating on the couch."
              />
            </picture>
          </section>
        </Block>
        <PageIntro>
          <h4 className="optimal-width optimal-width--center">
            Versett is designed to be a place for doing meaningful work. Our
            team is tight-knit and dedicated to building great products.
          </h4>
          <h4 className="optimal-width optimal-width--center">
            Working at Versett is about growth and development, culture, and
            collaboration:
          </h4>

          <h4 className="optimal-width optimal-width--center">
            <strong>Growth & Development: </strong> training, certifications,
            career trajectory
          </h4>
          <h4 className="optimal-width optimal-width--center">
            <strong>Culture: </strong> coaching, events, community, people-first
          </h4>
          <h4 className="optimal-width optimal-width--center">
            <strong>Collaboration: </strong> shared projects, partnerships,
            working with a global team
          </h4>
          <br />
          <h3 className="optimal-width optimal-width--center">
            <strong>How to Apply</strong>
          </h3>
          <h4 className="optimal-width optimal-width--center">
            Explore the links below to review open roles in North America and
            Europe. From there, our People & Culture team will reach out to
            qualified applicants, and we’ll move through our recruitment
            process, which includes technical validation, interviews with
            leaders, peer interviews, and reference checks.
          </h4>
          <h4 className="optimal-width optimal-width--center">
            And remember—as part of your application, be sure to tell us about
            something you’re proud of, whether it’s a story or a project.
          </h4>
          <br />
          <section className="optimal-width optimal-width--center s-certification">
            <img
              className="s-certification--img"
              src="/images/great-place-to-work-2022.png"
              alt="Great Place to Work Certification"
            />
            <p className="s-certification--text">
              We’re proud to announce that we have been certified as a Great
              Place to Work® after a thorough and independent analysis conducted
              by Great Place to Work® Institute Canada. This certification is
              based on direct feedback from employees, provided as part of an
              extensive and anonymous survey about the workplace experience.
            </p>
          </section>
        </PageIntro>
        <Block>
          <section className="s-container s-section s-careers-list">
            <div className="grid grid--2col-1-1">
              <h2 className="heading">
                <a
                  href="https://cbtw.bamboohr.com/careers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Explore Open Roles in North America
                </a>
              </h2>
              <h2 className="heading">
                <a
                  href="https://www.linkedin.com/company/versett-europe/jobs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Explore Open Roles in Europe
                </a>
              </h2>
            </div>
          </section>
        </Block>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query careersPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CareersPage
